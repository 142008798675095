import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {TokenService} from '../services/token.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private tokenService: TokenService, private router: Router) {
    }

    canActivate() {
        if (this.tokenService.isLoggedIn()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
