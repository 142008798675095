import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {MatomoModule} from 'ngx-matomo';
// import {SelftestService} from './services/selftest.service';
import {OneSignal} from '@ionic-native/onesignal/ngx';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        MatomoModule.forRoot({
            scriptUrl: 'https://stats.suchtberodungonline.lu/',
            trackers: [
                {
                    trackerUrl: 'https://stats.suchtberodungonline.lu/',
                    siteId: 2
                }
            ],
            routeTracking: {
                enable: true
            }
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        OneSignal
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    // constructor(private selftestService: SelftestService) {
    //     const substanceId = 1; // TODO: replace with a loop
    //     selftestService.getSubstancesQuestions(substanceId);
    // }
}
