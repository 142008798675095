import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Events} from './events.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    token = '';

    // recheck this, expiration and resetting needs to be done - TODO / FIXME
    constructor(
        private events: Events,
    ) {
        if (localStorage.getItem('token')) {
            this.token = JSON.parse(localStorage.getItem('token'));
        }
    }

    saveToken(token) {
        this.token = token;
        localStorage.setItem('token', JSON.stringify(token));
    }

    login(token) {
        this.saveToken(token);
        localStorage.setItem('user', JSON.stringify(this.getUserTokenInformation()));
        this.events.publish('user.logged_in');
    }

    returnToken() {
        return this.token;
    }

    returnHeader(type = 'application/json') {
        const token = this.returnToken(); // getting token from Service
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': type,
                'Authorization': 'Bearer' + ' ' + token,
            })
        };

        return httpOptions;
    }

    removeToken() {
        this.token = '';
        localStorage.removeItem('token');
    }

    logout() {
        this.removeToken();
        localStorage.removeItem('user');
        this.events.publish('user.logged_out');
    }

    isLoggedIn() {
        const helper = new JwtHelperService();
        // const expirationDate = helper.getTokenExpirationDate(this.token);
        const isLoggedIn = !helper.isTokenExpired(this.token); // checking if token is expired

        if (!isLoggedIn) {
            this.removeToken();
        }

        return isLoggedIn;
    }

    getUserTokenInformation() {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.token); // getting data from token

        if (decodedToken) {
            return {
                username: decodedToken.username,
                id: decodedToken.id,
                roles: decodedToken.roles,
                lang: decodedToken.lang,
                devices: decodedToken.devices,
                isPushNotificationAllowed: decodedToken.isPushNotificationAllowed,
                isEmailNotificationAllowed: decodedToken.isEmailNotificationAllowed
            };
        }
    }

    getUserLocalInformation() {
        return JSON.parse(localStorage.getItem('user'));
    }

    setUserLocalInformationProperty(property, value) {
        const user = JSON.parse(localStorage.getItem('user'));
        user[property] = value;
        localStorage.setItem('user', JSON.stringify(user));
    }

    isTherapist() {
        if (this.getUserTokenInformation()) {
            const roles = this.getUserTokenInformation().roles;
            return roles.includes('ROLE_THERAPIST');
        }
    }
}
