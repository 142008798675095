import {Component, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TokenService} from './services/token.service';
import {UrlBuilderService} from './services/urlbuilder.service';
import {Events} from './services/events.service';
import {Router} from '@angular/router';

import {AlertController} from '@ionic/angular';

import {OneSignal} from '@ionic-native/onesignal/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    activeLang = 'de';
    appLanguages = environment.appLanguages;
    online = true;

    public onlineOffline: boolean = navigator.onLine;

    public appPages = [];

    public clientAppPages = [
        {
            title: 'menu.consulting',
            url: '/consulting',
            icon: 'icon-consulting.svg'
        },
        {
            title: 'menu.diary',
            url: '/diary',
            icon: 'icon-diary.svg'
        },
        {
            title: 'menu.selftest',
            url: '/selftests',
            icon: 'icon-selftest.svg'
        },
        {
            title: 'menu.be-informed',
            url: '/be-informed',
            icon: 'icon-be-informed.svg'
        }
    ];

    public therapistAppPages = [
        {
            title: 'menu.consulting',
            url: '/client-list',
            icon: 'icon-consulting.svg'
        },
        {
            title: 'menu.diary',
            url: '/diary',
            icon: 'icon-diary.svg'
        },
        {
            title: 'menu.selftest',
            url: '/selftests',
            icon: 'icon-selftest.svg'
        },
        {
            title: 'menu.be-informed',
            url: '/be-informed',
            icon: 'icon-be-informed.svg'
        }
    ];

    public accountPages = [
        {
            title: 'menu.login',
            url: '/login',
            icon: '...'
        },
        {
            title: 'menu.register',
            url: '/register',
            icon: '...'
        }
    ];

    public infoPages = [
        {
            title: 'menu.about-us',
            url: '/about-us',
            icon: '...'
        },
        {
            title: 'menu.contact-us',
            url: '/contact-us',
            icon: '...'
        },
        {
            title: 'menu.faq',
            url: '/faq',
            icon: '...'
        }
    ];

    constructor(
        private http: HttpClient,
        public translate: TranslateService,
        private ngZone: NgZone,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private tokenService: TokenService,
        private events: Events,
        private router: Router,
        private translateService: TranslateService,
        private urlBuilder: UrlBuilderService,
        public alertController: AlertController,
        public oneSignal: OneSignal
    ) {
        this.initializeApp();

        // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang('de');
        // the lang to use, if the lang isn't available, it will use the current loader to get them

        // Check if user information are stored to get its language
        if (this.tokenService.getUserLocalInformation()) {
            if (this.tokenService.getUserLocalInformation().lang !== null) {
                this.activeLang = this.tokenService.getUserLocalInformation().lang;
            }
        }
        translate.use(this.activeLang);


        // Switch menu after logging in (if necessary)
        events.subscribe('user.logged_in', () => {
            this.switchMenu();
            // Put the app in the user language
            this.activeLang = this.tokenService.getUserLocalInformation().lang;
            translate.use(this.activeLang);
        });

        // Navigate back to home page after logging out
        events.subscribe('user.logged_out', () => {
            this.router.navigate(['/home']);
            // TODO: remove everything from local storage
        });

        this.registerDelayedUpload();


        /**
         *    Offline state
         */

        // offline
        if (!navigator.onLine) {
            this.online = false;
            console.log('offline');
        }

        // lost connection
        window.addEventListener('offline', () => {
            this.online = false;
            console.log('offline now');
        });

        // recovered connection
        window.addEventListener('online', () => {
            this.online = true;
            console.log('online now');
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleLightContent();
            this.splashScreen.hide();

            // Enable onesignal for push notifications
            if (this.platform.is('cordova')) {
                this.OneSignalInit();
            }

            // Show the default menu or the therapist menu depending on whether the user is logged in or out.
            this.switchMenu();
        });
    }

    private switchMenu() {
        if (this.tokenService.isTherapist()) {
            this.appPages = this.therapistAppPages;
        } else {
            this.appPages = this.clientAppPages;
        }
    }

    isLoggedIn() {
        return this.tokenService.isLoggedIn();
    }

    logOut() {
        this.confirmLogOutAlert();
    }

    changeLanguage(lang) {
        this.activeLang = lang;

        // update / reload translations
        this.translate.use(lang);

        // Send updated language to server if user logged in
        if (this.tokenService.isLoggedIn()) {
            const languageUpdateUrl = this.urlBuilder.build('/api/user/language');
            const httpOptions = this.tokenService.returnHeader();
            const request = this.http.post<any>(languageUpdateUrl, {'lang': lang}, httpOptions).pipe(/* retry(1), publishReplay(1), refCount()*/);
            request.subscribe((result: any) => {
                // console.log(result);
            }, error => {
                console.log(error);
            });
        }

        this.events.publish('diary.updated');
        this.events.publish('language.switched');
    }

    registerDelayedUpload() {
        this.events.subscribe('user.logged_in', () => {
            // TODO: check if data is in localstorage
            const selftestResultToSent = false;

            // check if there is anything to be sent

            if (selftestResultToSent) {
                // send TODO: use service
                // this.save(this.selftestResultToSent);
                // delete temporary variable
                // redirect
                this.router.navigate(['/results']);
            } else {
                this.router.navigate(['/home']);
            }
        });
    }

    // logOut Confirmation Alert
    async confirmLogOutAlert() {
        let alertContent = {
            title: '',
            content: '',
            cancel: '',
            confirm: ''
        };

        this.translateService.get('menu.alert').subscribe(value => alertContent = value);

        const alert = await this.alertController.create({
            header: alertContent.title,
            message: alertContent.content,
            buttons: [
                {
                    text: alertContent.cancel,
                    role: 'cancel',
                    handler: () => {

                    }
                }, {
                    text: alertContent.title,
                    handler: () => {
                        this.tokenService.logout();
                        // Switch the menu back to the standard version after logging out
                        this.switchMenu();
                    }
                }
            ]
        });

        await alert.present();
    }

    // Call this function when your app starts
    OneSignalInit(): void {
        // Uncomment to set OneSignal device logging to VERBOSE
        // OneSignal.setLogLevel(6, 0);

        this.oneSignal.startInit(environment.oneSignalAppId, environment.googleProjectNumber);

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

        this.oneSignal.handleNotificationOpened().subscribe(openedEvent => {
            // Executed when user open a push notification.
            // Redirect to consulting page
            this.ngZone.run(() => this.router.navigate(['/consulting']));
        });

        // iOS - Prompts the user for notification permissions.
        this.oneSignal.promptForPushNotificationsWithUserResponse().then(accepted => {
            console.log("User accepted notifications: " + accepted);
        });
        this.oneSignal.endInit();
    }

}
