import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {ConsultingGuard} from './guards/consulting.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'consulting',
        loadChildren: './consulting/consulting.module#ConsultingPageModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'diary',
        loadChildren: './diary/diary.module#DiaryPageModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsPageModule',
        canActivate: [AuthGuard],
    },
    {path: 'selftests', loadChildren: './selftests/selftests.module#SelftestsPageModule'},
    {path: 'be-informed', loadChildren: './be-informed/be-informed.module#BeInformedPageModule'},
    {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
    {path: 'selftest', loadChildren: './selftests/selftest/selftest.module#SelftestPageModule'},
    {path: 'result', loadChildren: './selftests/result/result.module#ResultPageModule'},
    {path: 'results', loadChildren: './selftests/results/results.module#ResultsPageModule'},
    {path: 'register', loadChildren: './register/register.module#RegisterPageModule'},
    {path: 'entry', loadChildren: './diary/entry/entry.module#EntryPageModule'},
    {path: 'progress', loadChildren: './diary/progress/progress.module#ProgressPageModule'},
    {
        path: 'client-list',
        loadChildren: './client-list/client-list.module#ClientListPageModule',
        canActivate: [AuthGuard, ConsultingGuard]
    },
    {path: 'client-info', loadChildren: './client-list/client-info/client-info.module#ClientInfoPageModule'},
    {path: 'lost-password', loadChildren: './login/lost-password/lost-password.module#LostPasswordPageModule'},
    {path: 'about-us', loadChildren: './about-us/about-us.module#AboutUsPageModule'},
    {path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule'},
    {path: 'page/:slug', loadChildren: './page/page.module#PagePageModule'},
    {path: 'substances', loadChildren: './be-informed/substances/substances.module#SubstancesPageModule'},
    {path: 'substance/:slug', loadChildren: './be-informed/substances/substance/substance.module#SubstancePageModule'},
    {path: 'faq', loadChildren: './faq/faq.module#FaqPageModule'},
    {path: 'organization/:slug', loadChildren: './about-us/organization/organization.module#OrganizationPageModule'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    providers: [AuthGuard, ConsultingGuard],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
