import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRoute, Router} from '@angular/router';
import {TokenService} from '../services/token.service';

@Injectable()
export class ConsultingGuard implements CanActivate {
    constructor(private tokenService: TokenService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    canActivate() {
        if (this.tokenService.isTherapist()) {
            return true;
        } else {
            return false;
        }
    }
}
