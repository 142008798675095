import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UrlBuilderService {
    constructor(
        public translate: TranslateService,
    ) {

    }

    /**
     * Encode a given JavaScript object as URL parameters
     */
    private static encodeQueryParameters(params: any) {
        const result = [];
        for (const param in params) {
            if (params.hasOwnProperty(param)) {
                result.push(encodeURIComponent(param) + '=' + encodeURIComponent(params[param]));
            }
        }
        return result.join('&');
    }

    build(endpoint: string, params: any = {}) {
        params._locale = this.translate.currentLang;
        return environment.apiHost + endpoint + '?' + UrlBuilderService.encodeQueryParameters(params);
    }
}
